.card {
  font-family: "Poppins", sans-serif !important;
  font-size: smaller;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.25rem;
  border: none;
}
.header {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  gap: .25rem;
  margin: 0.5rem;
  margin-top: -.75rem;
  color: rgb(50, 50, 50);
  font-size: smaller;
  font-weight: 600;
  margin-bottom: 1rem;
}
#p1 {
  font-weight: 800;
  text-align: start;
  color: rgb(50, 50, 50);
  font-size: small;
}
.cardIn {
    display: flex;
    font-size: smaller;
  color: rgb(50, 50, 50);
}
p {
  font-size: 0.75rem;
  color: rgb(88, 88, 88);
}
.chip{
  display: flex;
  align-items: center;
  gap: .5rem;
  width: 100%;
}
.chip p{
padding: .75rem;
display: flex;
align-items: center;
justify-content: center;
color: rgb(255, 255, 255);
background-color: #59714f;
border-radius: 16px;
font-size: small;
font-weight: bold;
}
.chipGroup{
  width: 40%;
  gap: .5rem;
  display: flex;
  
}
.tag{
  position: absolute;
  transform: scale(1.05);
}
.tag p{
  padding: .65rem .25rem .65rem .25rem;
  color:#62c14b;
  background-color: rgb(37, 37, 37);
  font-size: small;
}
#qtd{
  font-size: medium;
}
.img{
  cursor: pointer;
  transition: .2s all;
  /* transform: translate(-50%, -50%); */
}
/* .img:hover{
  transform: scale(1.04);
} */
.img:hover{
  transform: scale(1.03);
}
.disponivel{
    padding: 0 .5rem 0 .5rem;
    margin: 0;
    display: grid;
    gap: .5rem;
    width: 100%;
}
.table{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.p-button-secondary{
  background-color: white !important;
  border-color: lightgray !important;
  color: black !important;
}
#desconto{
  font-size: .85rem;
}
#quantidade{
  padding: 1rem 1.25rem 1rem 1.25rem;
}

@media(max-width: 960px){
  .chipGroup{
    width: 100%;
    gap: .5rem;
    display: flex;
    
  }
}