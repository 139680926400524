body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.p-component{
  font-family: "Poppins", sans-serif !important;
}
body::-webkit-scrollbar{
  width: .5vw;
  border-radius: 2rem;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(102, 102, 102, 0.6); 
  border-radius: 2rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(102, 102, 102, 1);  
}
.p-slidemenu{
  height: 75vh;
  border: none !important;
}
.p-slidemenu-wrapper{
  height: 100% !important;
}
.p-card{
  box-shadow: none !important;
}
.p-menubar{
  justify-content: space-between;
  padding: .5rem 1rem .5rem 1rem !important;
}
.p-menubar .p-menubar-custom, .p-menubar .p-menubar-end{
  margin-left: 0 !important;
}
.p-button{
  background-color:  #59714f !important;
  border-color: #59714f !important;
}
.p-card .p-card-content{
  padding: 0 !important;
}
.p-card .p-card-body{
  padding: 0 !important;
}
.p-shadow-2{
  box-shadow: none !important;
}
table{
  border-collapse: none !important;
    width: auto !important;
    table-layout:auto !important;
}
.td{
  width: 8vw;
  display: grid;
  gap: .25rem;
}
.bt{
  width: 95%;
}
@media (max-width: 960px) {
  .td{
    width: 15vh;
    display: grid;
    gap: .25rem;
  }
}