.menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: .85rem !important;
}
.menuMobile {
  display: none;
  width: 100vw;
}
.btnMenu {
  cursor: pointer;
}
.endMenu {
  display: flex;
  gap: 0.5rem;
}
.search {
  display: flex;
  gap: 0.5rem;
  transition: 0.2s;
}
.searchvs{
  display: none;
  transition: 0.2s;
}
.btns {
  display: flex;
  gap: 0.5rem;
}
.bars{
  background: none !important;
  color: #59714f !important;
  border: none !important;
}
.pi-bars:before {
  content: "\e91d";
  font-size: 2rem;
  font-weight: bold;
}
@media (max-width: 960px) {
  .menu {
    display: none !important;
  }
  .menuMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem !important;
  }
  .endMenu {
    display: grid;
    gap: 0.5rem;
  }
  .search {
    display: flex;
    gap: 0.25rem;
  }
  .btns {
    display: flex;
    gap: 1rem;
  }
}
