/* CarouselDemo.css */

.carousel-demo .product-item .product-item-content {
    border: 0px solid var(--surface-d);
    border-radius: 0px;
    margin: 0rem;
    text-align: center;
    padding: 2rem 0;
}

.carousel-demo .product-item .product-image {
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
       